//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { Overlay } from './../classes/Overlay/Overlay.js'
import { copyTextToClipboard } from './functions.js'
//-----------------------------------------------------------------------------------------------------------------


// Fonction gérant le menu mobile
export function overlayMenu() {
  let doc = document
  let html = doc.querySelector('html')
  let timeout, i, delay = 0, activeMenu = 'noClass'
  let links = document.querySelectorAll('#overlayMenu .linksWrapper')
  let linksLength = links.length

  new Overlay({
    name: 'Menu',
    structure: {
      openingClass: 'openingMenu',
      closingClass: 'closingMenu',
      buttons: {
        open: '.btnSectionMenu',
        close: '.closeOverlay, header .secondary a, header .left a, #submitSearch',
        toggle: '.btnMenu',
        switch: '.btnSearch'
      }
    },
    event: {
      name: 'click',
      sensitive: true,
      speed: 800,
      opening: {
        callback: function(e) {
          if (window.innerWidth > 1024) {
            for (i=0; i<linksLength; i++) {
              if(html.classList.contains('show' + links[i].dataset.section))
                delay = 300
            }

            timeout = setTimeout(function() {
              activeMenu !== 'noClass' ? html.classList.remove(activeMenu) : ''
              activeMenu = 'show' + e.target.dataset.section
              html.classList.add(activeMenu)
            }, delay)
          }
        }
      },
      closing: {
        callback: function() {
          clearTimeout(timeout)
          $('.groundFloor li.open .subLvl1').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
          $('.groundFloor li.open').removeClass('open')
          delay = 0
          if (html.classList.contains(activeMenu))
            html.classList.remove(activeMenu)
          activeMenu = 'noClass'
        }
      }
    },
    options: {
      goToSelector: 'html'
    }
  })

  $('.btnSectionMenu').on('click', function() {
    $('.groundFloor li.open .subLvl1').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $('.groundFloor li.open').removeClass('open')
  })

  $('.groundFloor .btnSubLvl1').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.groundFloor li.open .subLvl1').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $('.groundFloor li.open').removeClass('open')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() { $(chosen).parent().addClass('open') }, 0)
      $('.subLvl1', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    }
    return false
  })
}


// Fonction gérant l'overlay du calendrier en mobile
export function overlayCalendar() {
  new Overlay({
    name: 'Calendar',
    structure: {
      buttons: {
        open: '.btnCalendar',
        close: '.closeOverlay'
      }
    },
    event: {
      name: 'click',
      speed: 800,
    }
  })
}


// Fonction gérant l'overlay de la recherche en mobile
export function overlaySearch() {
  new Overlay({
    name: 'Search',
    structure: {
      openingClass: 'openingSearch',
      closingClass: 'closingSearch',
      buttons: {
        toggle: '.btnSearch',
        close: '.closeOverlay, #overlay',
        switch: '.btnMenu'
      }
    },
    event: {
      name: 'click',
      speed: 800,
    },
    options: {
      goToSelector: 'html'
    }
  })
}


// Fonction gérant l'overlay s'ouvrant avec un timeout
export function overlayPopup() {
  new Overlay ({
    name: 'Popup',
    structure: {
      buttons: {
        close: '.closeOverlay, #overlay',
      }
    },
    event: {
      name: 'timeout',
      speed: 800,
      delay: 5000
    }
  })
}



// Fonction gérant l'overlay de partage
export function overlayShare() {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  new Overlay({
    name: 'Share',
    structure: {
      buttons: {
        toggle: '.btnShare',
        close: '#overlay, .closeOverlay',
      }
    },
    event: {
      name: 'click',
      speed: 300
    },
  })

  $('#copyShareBtn').click( function() {
    copyTextToClipboard(window.location.href)
    alert('L’adresse URL a bien été copiée sur votre presse-papiers.')
    return false
  })
}
