//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Création du slick de la bannière
export function slickBanner() {
  $('#slickBanner').slick({
    appendDots: '.slickDots',
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 500,
    autoplaySpeed: 6000,
    fade: true,
    pauseOnHover: false
  })
}


// Création du slick de la bannière
export function slickNews() {
  $('#slickNews').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 500,
    autoplaySpeed: 10000,
    slidesToShow: 3,
    variableWidth: true,
    responsive: [{
      breakpoint: 768,
      settings: { slidesToShow: 2 }
    }, {
      breakpoint: 550,
      settings: { slidesToShow: 1 }
    }]
  })

  $('.sNews .prev').on('click', () => {
    $('#slickNews').slick('prev')
  })
  $('.sNews .next').on('click', () => {
    $('#slickNews').slick('next')
  })
}


// Création du slick de la bannière
export function slickFastLinks() {
  $('#fastLinks').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 500,
    autoplaySpeed: 10000,
    slidesToShow: 4,
    responsive: [{
      breakpoint: 768,
      settings: { slidesToShow: 3 }
    },{
      breakpoint: 500,
      settings: { slidesToShow: 2 }
    }]
  })

  $('.fastLinkNav .prev').on('click', () => {
    $('#fastLinks').slick('prev')
  })
  $('.fastLinkNav .next').on('click', () => {
    $('#fastLinks').slick('next')
  })
}


// Création du slick de la bannière
export function slickAlerts() {
  if (document.querySelectorAll('#slickAlerts').length != 0) {
    $('#slickAlerts').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      draggable: true,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
    })

    let total = $('#slickAlerts .slick-slide:not(.slick-cloned)').length
    let displayedTotal = total < 10 ? + total : total
    $('.alerts.mobile .slickNav .total').html(displayedTotal)

    $('#slickAlerts').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      var slidePosition = nextSlide + 2 < 10 ? + (nextSlide + 1) : (nextSlide + 1)
      $('.alerts.mobile .slickNav .current').html(slidePosition)
    })
  }
}


// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 1000 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function() {
    $(carousel).slick('unslick')
  }, delai)
}
