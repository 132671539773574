//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'

import {
  View, Home, Detail, Search, News, NewsDetail, Events, EventDetail, Jobs, JobDetail, Contact
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------


export function setView() {

  // Page standart __________
  Barba.BaseView.extend({
    namespace: 'sdtView',
    onEnter:          function() {              },
    onEnterCompleted: function() { View.init()  },
    onLeave:          function() { View.leave() },
    onLeaveCompleted: function() {              }
  }).init()


  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter:          function() {},
    onEnterCompleted: function() { Home.initHome()  },
    onLeave:          function() { Home.leaveHome() },
    onLeaveCompleted: function() {}
  }).init()


  // Page de détail __________
  Barba.BaseView.extend({
    namespace: 'detailView',
    onEnter:          function() {},
    onEnterCompleted: function() { Detail.initDetail()  },
    onLeave:          function() { Detail.leaveDetail() },
    onLeaveCompleted: function() {}
  }).init()


  // Page de résultats de recherche __________
  Barba.BaseView.extend({
    namespace: 'searchView',
    onEnter:          function() {},
    onEnterCompleted: function() { Search.initSearch()  },
    onLeave:          function() { Search.leaveSearch() },
    onLeaveCompleted: function() {}
  }).init()


  // Page des actualités __________
  Barba.BaseView.extend({
    namespace: 'newsView',
    onEnter:          function() {},
    onEnterCompleted: function() { News.initNews()  },
    onLeave:          function() { News.leaveNews() },
    onLeaveCompleted: function() {}
  }).init()


  // Page des actualités __________
  Barba.BaseView.extend({
    namespace: 'newsDetailView',
    onEnter:          function() {},
    onEnterCompleted: function() { NewsDetail.initNewsDetail()  },
    onLeave:          function() { NewsDetail.leaveNewsDetail() },
    onLeaveCompleted: function() {}
  }).init()


  // Page des événements __________
  Barba.BaseView.extend({
    namespace: 'eventsView',
    onEnter:          function() {},
    onEnterCompleted: function() { Events.initEvents()  },
    onLeave:          function() { Events.leaveEvents() },
    onLeaveCompleted: function() {}
  }).init()


  // Page d'un événement __________
  Barba.BaseView.extend({
    namespace: 'eventDetailView',
    onEnter:          function() {},
    onEnterCompleted: function() { EventDetail.initEventDetail()  },
    onLeave:          function() { EventDetail.leaveEventDetail() },
    onLeaveCompleted: function() {}
  }).init()


  // Page des emplois __________
  Barba.BaseView.extend({
    namespace: 'jobsView',
    onEnter:          function() {},
    onEnterCompleted: function() { Jobs.initJobs()  },
    onLeave:          function() { Jobs.leaveJobs() },
    onLeaveCompleted: function() {}
  }).init()


  // Page d'un emploi __________
  Barba.BaseView.extend({
    namespace: 'jobDetailView',
    onEnter:          function() {},
    onEnterCompleted: function() { JobDetail.initJobDetail()  },
    onLeave:          function() { JobDetail.leaveJobDetail() },
    onLeaveCompleted: function() {}
  }).init()


  // Page des événements __________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter:          function() {},
    onEnterCompleted: function() { Contact.initContact()  },
    onLeave:          function() { Contact.leaveContact() },
    onLeaveCompleted: function() {}
  }).init()
}
