//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { OBSERVER, SCROLLER, PARALLAX, SCROLLFIRE } from './main.js'
import svg4everybody from 'svg4everybody/dist/svg4everybody.legacy.js'
import { removeHoverOnMobile } from './functions/helper.js'
import { clearInput, checkInput, magicSelect, formContact, textareaHeight, formJob, formSearch } from './functions/forms.js'
import { overlayMenu, overlayCalendar, overlaySearch, overlayPopup, overlayShare } from './functions/overlay.js'
import { scrollToBlock, drawers, dragAndSlide, calendar, alerts, fixedArrow, activeFilter, activePage, manageRubriqueHash, slugifyProvider, addSlugToShare, destroyCalendar, bannerHeight } from './functions/functions.js'
import { slickBanner, slickNews, slickAlerts, slickFastLinks } from './functions/slicks.js'
import { ieCustomEvent } from './functions/polyfills.js'
//-----------------------------------------------------------------------------------------------------------------

window.activeFilter = activeFilter
window.activePage = activePage
window.calendar = calendar
window.slugifyProvider = slugifyProvider

// Initialisation sur toutes les pages
export class View {
  static init() {
    ieCustomEvent()
    SCROLLER.init()
    PARALLAX.init({ customScroll: true })
    SCROLLFIRE.init({ customScroll: true, mobile: true })
    scrollToBlock({ buttons: '#fixedArrow' })
    removeHoverOnMobile()
    svg4everybody()
    overlaySearch()
    overlayShare()
    overlayPopup()
    overlayMenu()
    clearInput()
    checkInput()
    fixedArrow()
    addSlugToShare()
    formSearch('#formSearch', '#submitSearch')
    formSearch('#formSearch2', '#submitSearch2')
  }

  static leave() {
    setTimeout(() => {
      SCROLLER.destroy()
      PARALLAX.destroy()
      SCROLLFIRE.destroy()
      OBSERVER.allOff()
    }, 400)
  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    this.init()
    alerts()
    slickNews()
    slickAlerts()
    slickBanner()
    dragAndSlide()
    bannerHeight()
    slickFastLinks()
    scrollToBlock({ buttons: '#scrollDown', destination: '#destination' })
  }

  static leaveHome() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de détail
export class Search extends View {
  static initSearch() {
    this.init()
    drawers()
  }

  static leaveSearch() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de détail
export class Detail extends View {
  static initDetail() {
    this.init()
    drawers()
    manageRubriqueHash()
  }

  static leaveDetail() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page des actualités
export class News extends View {
  static initNews() {
    this.init()
  }

  static leaveNews() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page d'une actualité
export class NewsDetail extends View {
  static initNewsDetail() {
    this.init()
  }

  static leaveNewsDetail() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page des événements
export class Events extends View {
  static initEvents() {
    this.init()
    calendar()
    dragAndSlide()
    overlayCalendar()
  }

  static leaveEvents() {
    this.leave()
    destroyCalendar()
  }
}


// Classe initialisant les scripts présents dans la page de détail d'un événement
export class EventDetail extends View {
  static initEventDetail() {
    this.init()
  }

  static leaveEventDetail() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page des emplois
export class Jobs extends View {
  static initJobs() {
    this.init()
    magicSelect()
    formJob()
  }

  static leaveJobs() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page d'un emploi
export class JobDetail extends View {
  static initJobDetail() {
    this.init()
    formJob()
  }

  static leaveJobDetail() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de contact
export class Contact extends View {
  static initContact() {
    this.init()
    drawers()
    magicSelect()
    formContact()
    textareaHeight()
    scrollToBlock({ buttons: '#scrollDown', destination: '#destination' })
    manageRubriqueHash()
  }

  static leaveContact() {
    this.leave()
  }
}

