export function getScrollbarWidth() {
  let doc = document
  const outer = doc.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll'
  outer.style.msOverflowStyle = 'scrollbar'
  doc.body.appendChild(outer)
  const inner = doc.createElement('div')
  outer.appendChild(inner)
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth)
  outer.parentNode.removeChild(outer)
  return scrollbarWidth
}
