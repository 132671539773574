//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { OBSERVER, SCROLLER } from './../main.js'
import $ from 'jquery'
import Barba from 'barba.js'
//-----------------------------------------------------------------------------------------------------------------

// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
export function scrollToBlock(object) {
  object.destination === undefined ? object.destination = 0 : object.destination
  object.duration    === undefined ? object.duration = 600  : object.duration
  object.offset      === undefined ? object.offset = 0      : object.offset

  OBSERVER.add('scrollToBlock', 'click', onClick, object.buttons)
  OBSERVER.on('scrollToBlock')

  function onClick() {
    let destination
    destination = (object.destination === 0 ? object.destination : SCROLLER.getElementOffset(document.querySelector(object.destination)).top)
    if($(window).width() <= 1024) object.offset = 0
    $('html, body').animate({ scrollTop: destination + object.offset }, object.duration, 'easeInOutExpo')
  }
}


// Fonction initialisant les tiroirs
export function drawers() {
  $('.drawers > li .toggle').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.drawers li.open .drawer').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $('.drawers li.open').removeClass('open')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() { $(chosen).parent().addClass('open') }, 0)
      $('.drawer', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }, complete: function() {
        $('html, body').animate({ scrollTop: $(chosen).offset().top }, 600, 'easeInOutExpo')
      }})
    }
    return false
  })
}


// Fonction gérant la flèche en position fixed
export function fixedArrow() {
  let arrow = document.getElementById('fixedLinks')

  OBSERVER.add('fixedArrow', SCROLLER.getScrollEvent(), onScroll)
  OBSERVER.on('fixedArrow')

  function onScroll() {
    let ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    let wh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    if (SCROLLER.getPageOffset() > 300 && (SCROLLER.getPageOffset() < (SCROLLER.getElementOffset(document.querySelector('footer')).top - wh) || ww > 1024))
      arrow.classList.add('active')
    else
      arrow.classList.remove('active')
  }
}


// Permet de slider les catégories lorsqu'ils sont à l'horizontale
export function dragAndSlide() {
  const slider = document.querySelector('.dragAndSlide')
  let startX, timeout, scrollLeft, isDown = false, preventDefault = false

  function mouseDown(e) {
    isDown = true
    slider.classList.add('active')
    startX = e.pageX - slider.offsetLeft
    scrollLeft = slider.scrollLeft
    timeout = setTimeout(() => {
      preventDefault = true
    }, 300)
  }

  function mouseleave() {
    isDown = false
    slider.classList.remove('active')
  }

  function mouseup() {
    clearTimeout(timeout)
    isDown = false
    slider.classList.remove('active')
  }

  function mousemove(e) {
    if(!isDown) return
    e.preventDefault()
    const x = e.pageX - slider.offsetLeft
    const walk = (x - startX) * 2
    slider.scrollLeft = scrollLeft - walk
  }

  function click(e) {
    if(preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    }
    preventDefault = false
  }

  OBSERVER.add('dragAndSlide', 'mousedown', mouseDown, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'mouseleave', mouseleave, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'mouseup', mouseup, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'mousemove', mousemove, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'click', click, '.dragAndSlide a')
  OBSERVER.on('dragAndSlide')
}


// Initialisation du calendrier
export function calendar() {
  if (!document.querySelector('#calendarOptions')) //s'il n'y a pas d'events
    return

  let eventsDatesList = document.querySelector('#calendarOptions').dataset.list
  let currentDate = document.querySelector('#calendarOptions').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  var datesEnabled = eventsDatesList.split(',')

  $('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    var theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) { data.then(function(data){ Barba.Pjax.goTo('/evenements/1/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $('.calendar').datepicker('update', currentDate)
}

export function destroyCalendar() {
  $('.calendar').datepicker('destroy')
}


export function alerts() {
  OBSERVER.add('alerts', 'click', desktop, '.alerts.desktop .alertWrapper .close')
  OBSERVER.add('alerts', 'click', mobile, '.alerts.mobile .close')
  OBSERVER.on('alerts')

  function desktop() {
    $(this).parent().animate({ height: 'hide', opacity: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
  }
  function mobile() {
    document.querySelector('.sBanner').classList.remove('showMobileAlert')
  }
}


export function addVideo() {
  // if(!isMobile()) {
  //   $('.videoWrapper').append('<video muted playsinline loop="loop" align="left" autoplay="autoplay"><source src="'+ $(location).attr('protocol') + '//' + $(location).attr('hostname') +'/themes/blanko/assets/videos/video.mp4" type="video/mp4"></video>')
  // }
}

export function activeFilter(element) {
  $('.categFilter').removeClass('active')
  $(element).addClass('active')
}

export function activePage(element) {
  $('.page').removeClass('active')
  $(element).addClass('active')
}

export function manageRubriqueHash() {
  //Pour ouvrir une rubrique dans la même page
  window.onhashchange = function () {
    var id = location.hash.split('#').pop()
    var hash = '#rubrique' + id
    if ($('li' + hash).length) {
      hashDrawer(hash)
    }
  }

  //Pour ouvrir une rubrique dans une autre page
  $('document').ready(function () {
    if (location.hash.length) {
      var id = location.hash.split('#').pop()
      var hash = '#rubrique' + id
      if ($('li' + hash).length) {
        hashDrawer(hash)
      }
    }
  })
}

// Permet de défiler à une ancre
function hashScrolling(destination) {
  // let offset = document.querySelector('.sRubric > div').dataset.scrollfire != 'fire' ? -100 : 0
  if (SCROLLER.getPageOffset() == 0)
    $('html, body').animate({ scrollTop: (destination != '' ? $(destination).offset().top - 100 : 0) }, 600, 'easeInOutExpo')
}

// Permet de gérer un tiroir avec un défilement
function hashDrawer(element) {
  $('.drawers li.open .drawer').animate({ height: 'hide' }, 400)
  $('.drawers li.open').removeClass('open')
  if (!$(element).hasClass('open')) {
    setTimeout(function () { $(element).addClass('open') }, 0)
    $('.drawer', $(element)).stop(true, false).animate({ height: 'show' }, 400, function () { hashScrolling(element) })
    //Enleve l'ancre de l'url une fois que la rubrique est ouverte
    history.replaceState(null, null, ' ')
  }
  return false
}

//Permet d'enlever les accents d'une string
export function slugifyProvider(provider) {
  let result
  result = provider.replace('é', 'e')
  result = result.replace('É', 'E')
  result = result.toLowerCase()

  return result
}

export function copyTextToClipboard(text) {
  // if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  // }
  // navigator.clipboard.writeText(text).then(function() {
  //   console.log('Async: Copying to clipboard was successful!')
  // }, function(err) {
  //   console.error('Async: Could not copy text: ', err)
  // })
}

function fallbackCopyTextToClipboard(text) {
  var pos = $(document).scrollTop()

  var textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


export function addSlugToShare() {
  $('.btnShare.rubric').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.id)
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Saint-Barthélemy&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $('#overlay, .closeOverlay').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Saint-Barthélemy&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
  })
}


// Donne la hauteur de la bannière
export function bannerHeight() {
  let doc = document
  let height

  setHeight()

  OBSERVER.add('bannerHeight', 'resize', setHeight)
  OBSERVER.on('bannerHeight')

  function setHeight() {
    console.log('hi')
    height = window.innerHeight - doc.querySelector('header').clientHeight
    doc.querySelector('.sBanner').style.height = height + 'px'
  }
}
