//-------------------------\\
//---- Class Parallax ----\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Add parallax effect to an html element. You must add the attribut data-parallax on the element and add
// data-speed with a small number as the value (exemple: 0.5, 0.75, 1.1). You can add a data-offset
// to modify the position of the element
//
// new Parallax({
//   mobile: BOOLEAN[false] -> If it's active on mobile or not
// })
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class Parallax {

  // Initialization of the class
  init(object = {}) {
    object.mobile === undefined ? this.mobile = false : this.mobile = object.mobile

    // Detect if it's Internet explorer
    this.isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)
    if (!this.isIE) {

      // Define the scroll event
      document.querySelector('html').classList.contains('scroller') ? this.scrollEvent = 'smooth' : this.scrollEvent = 'scroll'

      // Variables
      this.tElements = [].slice.call(document.querySelectorAll('[data-parallax]'))
      this.tElementsLength = this.tElements.length

      // Detect if the user is on a mobile and if the scroll is active on mobile device
      this.mobileCondition = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      if (!this.mobileCondition || (this.mobileCondition && this.mobile))
        this.start()
    }
  }


  // Start the events
  start() {
    if (!this.isIE) {
      this.boundParallax = e => this.parallax(e)
      addEventListener(this.scrollEvent, this.boundParallax, false)
      addEventListener('DOMContentLoaded', this.boundParallax, false)
      addEventListener('load', this.boundParallax, false)
      addEventListener('resize', this.boundParallax, false)
    }
  }


  // Update the array of elements and the events
  update() {
    if (!this.isIE) {
      let i

      this.tElements = [].slice.call(document.querySelectorAll('[data-parallax]'))
      this.tElementsLength = this.tElements.length

      for (i=0; i<this.tElementsLength; i++)
        this.tElements[i].style.transform = 'none'

      removeEventListener(this.scrollEvent, this.boundParallax, false)
      this.boundParallax = e => this.parallax(e)
      addEventListener(this.scrollEvent, this.boundParallax, false)
    }
  }


  // Destroy the events, reset the transform value and empty the array
  destroy() {
    if (!this.isIE) {
      let i
      removeEventListener(this.scrollEvent, this.boundParallax, false)
      removeEventListener('DOMContentLoaded', this.boundParallax, false)
      removeEventListener('load', this.boundParallax, false)
      removeEventListener('resize', this.boundParallax, false)

      for (i=0; i<this.tElementsLength; i++)
        this.tElements[i].style.transform = 'none'

      this.tElements = []
    }
  }


  // Add the parallax on all elements
  parallax() {
    let i, offset, visible, centreEcran, elementOffset, centreElement, position

    centreEcran = window.innerHeight / 2

    for (i=0; i < this.tElementsLength; i++) {
      visible = this.isElementInViewport(this.tElements[i])
      offset = parseInt(( this.tElements[i].dataset.offset !== undefined ? this.tElements[i].dataset.offset : 0 ))

      if (visible) {
        elementOffset = this.tElements[i].getBoundingClientRect().top
        centreElement = elementOffset + ( this.tElements[i].clientHeight / 2 )
        position = centreElement - centreEcran
        this.tElements[i].style.transform = 'translateY(' + ( position * (this.tElements[i].dataset.speed / 10) + offset) + 'px)'
      }
    }
  }


  // Return if the element is in the viewport
  isElementInViewport(e) {
    let rect = e.getBoundingClientRect()

    return (
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight + 100 || document.documentElement.clientHeight + 100) &&
      rect.left <= (window.innerWidth  || document.documentElement.clientWidth)
    )
  }
}
