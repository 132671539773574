//-------------------------------\\
//--- Importation des scripts ---\\
//-------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main.js importe tous les fichiers qu'on a de besoin, ça commence par les node-modules, puis les fichiers
// propres au projet.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Importation des nodes modules __________
import $ from 'jquery'
import Barba from 'barba.js'
window.jQuery = $
import 'jquery.easing'
import 'jquery-validation'
import 'slick-carousel'
import 'slick-carousel/slick/slick-theme.scss'
import 'slick-carousel/slick/slick.scss'
import 'bootstrap-datepicker'
import 'jquery-mask-plugin'
import 'chosen-js'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js'

window.Barba = Barba

// Importation des scripts __________
import './transition.js'
import './global.js'


// Importation pour les scripts un peu plus bas __________
import { initPageTransition } from './transition.js'
import { Scrollfire } from './classes/Scrollfire.js'
import { Observer } from './classes/Observer.js'
import { Scroller } from './classes/Scroller.js'
import { Parallax } from './classes/Parallax.js'


// Démarrage des scripts __________
export const OBSERVER   = new Observer()
export const SCROLLER   = new Scroller()
export const PARALLAX   = new Parallax()
export const SCROLLFIRE = new Scrollfire()

$(document).ready(function() {
  initPageTransition()
  setTimeout(function() {
    $('html').removeClass('chargement')
  }, 600)
  $(document).scrollTop(0)

  $('body').keydown( function(e) {
    var keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey  && keycode == 13) || (e.metaKey  && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
})
